import { getReferralLink, changeReferralGreeting, getReferrals, getMoreReferrals } from '@/http/girl/referrals'
import { getLikes, getMoreLikes } from './likes'
import { getPosts, getMorePosts } from './posts'
// eslint-disable-next-line import/extensions
import { getProfileByNickname, getProfile } from './profile.js'
import { getFans, getMoreFans } from './fans'
import { getFavoriteComments, getMoreFavoriteComments } from './comments'
import { subscribe, unsubscribe } from './subscription'
import { getNotifications, getMoreNotifications } from './notifications'
import { enableEmailNotification, disableEmailNotification } from './emailNotification'
import { sendComplaint } from './complaints'
import { getTwoDaysStatistics, getStatistics, getStatisticFile } from './statistics/statistics'
import { getGirlsList } from './girlsList'
import {
	getConsumersLists,
	createConsumersList,
	updateConsumersList,
	removeConsumersList,
	getConsumers
} from './consumersLists'

export default {
	getFavoriteComments,
	getMoreFavoriteComments,
	getFans,
	getMoreFans,
	getLikes,
	getMoreLikes,
	getPosts,
	getMorePosts,
	getProfile,
	subscribe,
	unsubscribe,
	sendComplaint,
	getNotifications,
	getMoreNotifications,
	enableEmailNotification,
	disableEmailNotification,
	getTwoDaysStatistics,
	getStatistics,
	getStatisticFile,
	getGirlsList,
	getProfileByNickname,
	getReferralLink,
	changeReferralGreeting,
	getReferrals,
	getMoreReferrals
}

// named export
export { getConsumersLists, createConsumersList, updateConsumersList, removeConsumersList, getConsumers }
