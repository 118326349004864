const routes = [
	{
		path: '/author/:id',
		component: () => import('@/views/Girl/IndexId.vue'),
		meta: {
			auth: true
		},
		children: [
			{
				path: '/author/notifications',
				name: 'author-notifications-id',
				component: () => import('@/views/Girl/NotificationsView.vue'),
				meta: {
					title: 'Уведомления'
				}
			},
			{
				path: '/author/schedule',
				name: 'author-schedule',
				component: () => import('@/views/Girl/ScheduleView.vue'),
				meta: {
					layout: 'arrowBack',
					title: 'Запланированный контент',
					closeBtn: true,
					hideBackBtn: true,
					fixedHeader: true
				}
			},
			{
				path: '/author/schedule/:day/:week',
				name: 'author-schedule-detail',
				component: () => import('@/views/Girl/ScheduleDetailView.vue'),
				meta: {
					layout: 'arrowBack',
					title: 'Запланированный контент',
					closeBtn: true,
					backRouteName: 'author-schedule'
				}
			},
			{
				path: '',
				component: () => import('@/views/Girl/ProfileView.vue'),
				children: [
					{
						path: '',
						name: 'author-profile-id',
						meta: {
							title: 'Профиль'
						},
						component: () => import('@/views/Girl/PostsTab.vue')
					},
					{
						path: 'author-schedule',
						name: 'author-profile-schedule-id',
						component: () => import('@/views/Girl/PostsScheduleTab.vue')
					},
					{
						path: 'author-reactions',
						name: 'author-reactions-id',
						component: () => import('@/views/Girl/ReactionsTab.vue')
					}
				]
			},
			{
				path: 'posts',
				name: 'author-posts-id',
				component: () => import('@/views/Girl/PostsView.vue'),
				meta: {
					layout: 'balanceWithMenu',
					title: 'Посты'
				}
			},
			{
				path: 'posts-schedule',
				name: 'author-posts-schedule-id',
				component: () => import('@/views/Girl/PostsScheduleView.vue'),
				meta: {
					layout: 'balanceWithMenu',
					title: 'Запланированные посты'
				}
			},
			{
				path: 'fans',
				name: 'author-fans-id',
				component: () => import('@/views/Girl/FansView.vue'),
				meta: {
					layout: 'arrowBack',
					title: 'Фанаты'
				}
			},
			{
				path: 'likes',
				name: 'author-likes-id',
				component: () => import('@/views/Girl/LikesView.vue')
			},
			{
				path: 'referrals',
				name: 'author-referrals-id',
				component: () => import('@/views/Girl/ReferralsView.vue'),
				meta: {
					layout: 'arrowBack',
					title: 'Рефералы'
				}
			}
		]
	},
	{
		path: '/a/:nickname',
		component: () => import('@/views/Girl/Index.vue'),
		meta: {
			auth: true
		},
		children: [
			{
				path: '/a/notifications',
				name: 'author-notifications',
				component: () => import('@/views/Girl/NotificationsView.vue'),
				meta: {
					title: 'Уведомления'
				}
			},
			{
				path: '/author/statistics/withdraw-history',
				name: 'withdraw-history',
				component: () => import('@/views/Girl/WithdrawHistoryView.vue'),
				meta: {
					layout: 'arrowBack',
					title: 'История выплат'
				}
			},
			{
				path: '/author/regulations',
				name: 'author-regulations',
				component: () => import('@/views/Girl/RegulationsView.vue'),
				meta: {
					layout: 'arrowBack',
					title: 'Пользовательское соглашение'
				}
			},
			{
				path: '/author/statistics',
				name: 'author-statistics',
				component: () => import('@/views/Girl/StatisticsView.vue'),
				meta: {
					layout: 'arrowBack',
					title: 'Личный кабинет'
				}
			},
			{
				path: '/author/withdrawal',
				name: 'author-withdrawal',
				component: () => import('@/views/Girl/WithdrawalView.vue'),
				meta: {
					layout: 'arrowBack',
					title: 'Вывод средств'
				}
			},
			{
				path: '/author/profile/edit',
				name: 'author-profile-edit',
				component: () => import('@/views/Girl/ProfileEditView.vue'),
				meta: {
					title: 'Редактировать профиль',
					layout: 'empty'
				}
			},
			{
				path: '',
				component: () => import('@/views/Girl/ProfileView.vue'),
				children: [
					{
						path: '',
						name: 'author-profile',
						meta: {
							title: 'Профиль'
						},
						component: () => import('@/views/Girl/PostsTab.vue')
					},
					{
						path: 'author-schedule',
						name: 'author-profile-schedule',
						component: () => import('@/views/Girl/PostsScheduleTab.vue'),
						meta: {
							title: 'Профиль'
						}
					},
					{
						path: 'author-reactions',
						name: 'author-reactions',
						component: () => import('@/views/Girl/ReactionsTab.vue'),
						meta: {
							title: 'Профиль'
						}
					}
				]
			},
			{
				path: 'posts',
				name: 'author-posts',
				component: () => import('@/views/Girl/PostsView.vue'),
				meta: {
					layout: 'balanceWithMenu',
					title: 'Посты'
				}
			},
			{
				path: 'posts-schedule',
				name: 'author-posts-schedule',
				component: () => import('@/views/Girl/PostsScheduleView.vue'),
				meta: {
					layout: 'balanceWithMenu',
					title: 'Запланированные посты'
				}
			},
			{
				path: 'fans',
				name: 'author-fans',
				component: () => import('@/views/Girl/FansView.vue'),
				meta: {
					layout: 'arrowBack',
					title: 'Фанаты'
				}
			},
			{
				path: 'likes',
				name: 'author-likes',
				component: () => import('@/views/Girl/LikesView.vue'),
				meta: {
					layout: 'arrowBack',
					title: 'Лайки'
				}
			},
			{
				path: 'consumers-lists',
				name: 'consumers-lists',
				component: () => import('@/views/Girl/ConsumersListsView.vue'),
				meta: {
					layout: 'arrowBack',
					title: 'Списки пользователей'
				}
			},
			{
				path: 'consumers-lists/new',
				name: 'consumers-lists-new',
				component: () => import('@/views/Girl/ConsumersListNewView.vue'),
				meta: {
					layout: 'arrowBack',
					title: 'Мои фанаты'
				}
			},
			{
				path: 'referrals',
				name: 'author-referrals',
				component: () => import('@/views/Girl/ReferralsView.vue'),
				meta: {
					layout: 'arrowBack',
					title: 'Рефералы'
				}
			}
		]
	},
	{
		path: '/author/start-waiting-subscription/:code',
		name: 'author-start-waiting-subscription',
		component: () => import('@/views/Consumer/StartWaitingSubscriptionView.vue'),
		meta: {
			auth: undefined,
			layout: 'empty'
		}
	},
	{
		path: '/author/mk-start-waiting-subscription/:code',
		name: 'author-mk-start-waiting-subscription',
		component: () => import('@/views/Consumer/MkStartWaitingSubscriptionView.vue'),
		meta: {
			auth: undefined,
			layout: 'empty'
		}
	},
	{
		path: '/sign-in-as-test-girl',
		name: 'sign-in-as-test-girl',
		component: () => import('@/views/Girl/SignInAsTestGirl.vue'),
		meta: {
			auth: undefined,
			layout: 'empty'
		}
	}
]

export default routes
